@use "../../../../variables" as variables;

#app {
  height: auto;
  min-height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;

  .app-content {
    height: 100%;

    &:before,
    &:after {
      content: " ";
      display: table;
    }

    &:after {
      clear: both;
    }
  }

  @media (max-width: variables.$screen-sm-max) {
    width: 100%;

    .app-content {
      transition: transform 300ms ease 0s;
      height: 100%;
      width: 100%;
      position: relative;
      z-index: 1050;
    }
  }
}

// Main Content
.main-content {
  position: relative;

  .container {
    max-width: none !important;
    width: 100% !important;
    display: inline-block;
  }

  > .container {
    padding-bottom: 0;
  }
}
