@use "../../../../variables" as variables;
@use "../variables" as app_variables;

html,
body {
  height: 100%;
  width: 100%;
}

body {
  direction: ltr;
  -webkit-font-smoothing: antialiased !important;

  & > [class*="tw-"] {
    font-family: app_variables.$font-family-for-tw;
  }
}

::selection {
  background: variables.$green;
  text-shadow: none;
  color: white;
}

a {
  color: variables.$primary;

  &:hover,
  &:focus,
  &:active {
    color: app_variables.$darkPrimary;
    outline: 0;
    cursor: pointer;
    text-decoration: none;
  }
}

button {
  outline: 0;
}

h1,
h2,
h3,
h4,
h5 {
  &[class*="tw-"] {
    margin: 0;
  }

  &:where(:not([class*="tw-"])) {
    font-weight: 300;
    margin-top: 0;
    color: app_variables.$textDark;
  }
}

.nav-pills {
  > li.active {
    > a,
    > a:hover,
    > a:focus {
      background-color: variables.$primary;
      color: white;
    }
  }

  > li > a {
    border-radius: 10px;
  }
}

.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
  background-color: variables.$primary;
  border-color: variables.$primary;
}

.container-fullw {
  margin-left: -15px;
  margin-right: -15px;
  padding: 15px app_variables.$container-padding-side 30px;

  @media (max-width: app_variables.$screen-sm-min) {
    padding: 15px app_variables.$container-padding-side-sm 30px;
  }
}
