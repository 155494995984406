@use "../../../../variables" as variables;
@use "../variables" as app_variables;

.confrere-parent-alert {
  position: relative;

  .confrere-alert {
    position: absolute;
    padding: 0 8px;
  }
}

.btns-interest {
  position: fixed;
  right: 0;
  top: 50%;
  padding-right: 5px;

  .btn-switch {
    background: app_variables.$sidebarColor;
  }

  .like {
    background: app_variables.$resultGreen;
    height: 60px;
    width: 60px;
    border-radius: 41px;
    padding-top: 15px;
    border: app_variables.$border-separator-large;
    display: block;
    vertical-align: top;
    color: white;
  }

  .dislike {
    background: app_variables.$lightRed;
    height: 60px;
    width: 60px;
    border-radius: 41px;
    padding-top: 15px;
    border: app_variables.$border-separator-large;
    display: block;
    vertical-align: top;
    color: white;
  }

  .not-now {
    background: app_variables.$lightOrange;
    height: 60px;
    width: 60px;
    border-radius: 41px;
    padding-top: 15px;
    display: block;
    color: white;
    border: app_variables.$border-separator-large;
  }
}

.select {
  .btns-interest {
    position: initial;
    right: 0;
    top: 0;
    padding-right: 0;
    margin-left: -7px;
    margin-top: 7px;
    width: 20px;

    .dislike {
      background: app_variables.$lightRed;
      height: 32px;
      width: 32px;
      border-radius: 16px;
      padding-top: 2px;
      border: 3px solid variables.$gainsboro;
      display: block;
      vertical-align: top;
      color: white;
    }
  }
}

.thumbnail {
  .select {
    .btns-interest {
      margin-top: -7px;
    }
  }
}

.box-ventes-search {
  .tabbable {
    margin: 0;

    .search-criteria {
      .price {
        margin-bottom: 0;

        .input-price {
          .input-group {
            float: left;
          }

          .prix-hni {
            display: inline-block;

            @media (min-width: app_variables.$screen-md-min) {
              margin-left: 20px;
            }

            .checkbox {
              display: inline-block;
              margin: 5px 0;
            }
          }
        }
      }

      .ventes-etude {
        padding-top: 10px;
        margin-bottom: 0;

        .checkbox {
          margin: 0;
        }
      }
    }

    .search-reference {
      .input-group {
        width: 100%;

        input {
          border-right: 0;
        }

        .input-group-btn {
          border: variables.$border-separator;
          border-left: 0;
        }
      }
    }

    .search-localisation {
      .localisation-precision {
        @media (max-width: app_variables.$screen-sm-max) {
          height: 120px;

          .radio-inline {
            > * {
              margin: 0;
            }
          }
        }

        @media (min-width: app_variables.$screen-md-min) {
          height: 70px;

          > * {
            float: left;
          }

          .radio-inline {
            padding: 0;
          }

          .precision-fields.quartier {
            width: calc(100% - 200px);
          }

          .precision-fields {
            width: 100%;
          }
        }
      }
    }
  }
}

.vente-contrats {
  .contrats-mandats {
    .mandats-type {
      .form-group {
        margin: 0;

        .radio-inline {
          margin: 0 !important;

          .clip-radio {
            margin: 5px;

            label {
              margin: 0;
            }
          }
        }
      }
    }
  }

  .contrats-new {
    .new-generate {
      display: none;
    }
  }

  .contrats-none {
    .contrats-new {
      .new-generate {
        display: inline;
      }

      .new-create {
        display: none;
      }
    }
  }

  .contractors-list {
    border-right: 1px solid app_variables.$lightTextColor;
    padding-left: 5px;

    .contrats-new {
      margin: 10px calc((100% - 170px) / 2);
    }

    .contractors-item:first-child {
      border: none;
    }

    .contractors-item {
      border: none;
      border-top: variables.$border-separator;
      padding: 15px 10px;

      button.btn-link {
        float: right;
        right: -5px;
        bottom: 25px;
        color: variables.$textColor;
        padding: 0 12px;
        font-size: app_variables.$font-size-mega-large;
      }

      .contractor-contrats {
        margin: 10px 10px 0;
        color: variables.$textColor;

        .contrats-none,
        .contrats-total {
          border: none;
        }

        .contrats-item {
          border-radius: 0;
          padding: 10px;
          z-index: 1;
        }
      }
    }
  }

  .contrats-edition {
    .edition-actions {
      min-height: 50px;

      .tabbable {
        margin-bottom: 0;

        .tab-content {
          display: none;
        }
      }
    }
  }
}

.vente-modal-contrats {
  .modal-body {
    .contrats-mandant {
      margin-top: 20px;

      .mandant-explanation {
        padding-top: 7px;
        padding-left: 0;
        color: app_variables.$lightTextColor;
      }
    }
  }
}

.vente-archive {
  .modal-body {
    .reference-demandeur {
      .demandeurs-archives {
        .checkbox {
          label {
            color: app_variables.$lightTextColor;
          }
        }
      }
    }

    esk-select-date {
      display: inline-block;
    }
  }
}

.ventes {
  .ventes-negociees {
    .ventes-negociees-container {
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;

      @media (min-width: app_variables.$screen-md-min) {
        max-width: calc(#{app_variables.$screen-md-min} - 40px);
      }

      @media print {
        esk-ventes-chart-negociees {
          height: 530px;
          display: block;
          position: relative;

          .negociees-canvas {
            max-width: 100%;
            position: relative;

            canvas {
              position: absolute;
              left: 0;
              top: 0;
              right: 0;
              bottom: 0;
              width: 100% !important;
            }
          }

          p {
            bottom: 0;
            right: 0;
            position: absolute;
          }
        }
      }
    }

    .ventes-negociees-etude {
      .etude-table {
        margin-top: 50px;
      }
    }

    .ventes-negociees-natures {
      .natures-table {
        .table-filter {
          .filter-annee {
            margin-left: 30px;

            @media (max-width: app_variables.$screen-xs-max) {
              margin-left: 0;
            }

            input {
              max-width: 120px;
            }
          }

          @media print {
            > * {
              width: 50%;
              float: left;
            }
          }
        }
      }
    }
  }

  .vente {
    tabs {
      .tab-container {
        margin-bottom: 10px;

        .tab-content {
          display: none;
        }
      }
    }

    .vente-edit {
      .prix-edit {
        .prix-information {
          color: variables.$textColor;
          width: 100px;

          a {
            padding-right: 0;
          }

          .popover {
            width: 170px;
          }
        }

        .types-charges {
          .radio-inline {
            margin: 0 !important;

            .radio {
              margin: 0 !important;
            }
          }
        }

        .interactive-pas-offre,
        .interactive-date-fin {
          .btn-null {
            padding-top: 0;
            padding-bottom: 0;
          }
        }
      }

      .accessibilite_environnement-edit {
        .accessibilite_environnement-plain_pied {
          max-width: 200px;
        }
      }
    }
  }
}

.list-ventes {
  .select-all {
    .clip-check input[type="checkbox"]:checked + label:before {
      border-color: variables.$primary;
    }
  }
}

.vente-price {
  cursor: default;
}

esk-box-new-ventes {
  .panel {
    .panel-body {
      padding: 0 15px;
    }
  }
}

esk-ventes-chart-negociees {
  .negociees-legend {
    > * {
      display: inline-block;
      margin-left: 40px;
    }

    .legend-bars {
      > * {
        display: inline-block;
        width: 30px;
      }

      .legend-bar-year {
        position: relative;

        > * {
          position: absolute;
          top: -22px;
        }
      }

      .legend-bar-previous,
      .legend-bar-current {
        > * {
          height: 15px;
        }
      }

      .legend-bar-previous {
        .bar-solo {
          background-color: app_variables.$archiveVenteSoloPrevious;
        }

        .bar-duo {
          background-color: app_variables.$archiveVenteDuoPrevious;
        }
      }

      .legend-bar-current {
        .bar-solo {
          background-color: app_variables.$archiveVenteSolo;
        }

        .bar-duo {
          background-color: app_variables.$archiveVenteDuo;
        }
      }

      @media print {
        .legend-bar-previous {
          .bar-solo {
            background-color: unset;
            box-shadow: inset 0 0 0 1000px app_variables.$archiveVenteSoloPrevious !important;
          }

          .bar-duo {
            background-color: unset;
            box-shadow: inset 0 0 0 1000px app_variables.$archiveVenteDuoPrevious !important;
          }
        }

        .legend-bar-current {
          .bar-solo {
            background-color: unset;
            box-shadow: inset 0 0 0 1000px app_variables.$archiveVenteSolo !important;
          }

          .bar-duo {
            background-color: unset;
            box-shadow: inset 0 0 0 1000px app_variables.$archiveVenteDuo !important;
          }
        }
      }

      &.without-network {
        .legend-bar-year {
          > * {
            top: -15px;
          }
        }

        .legend-bar-text,
        .bar-duo {
          display: none;
        }
      }
    }

    .legend-line {
      vertical-align: text-bottom;

      > * {
        display: inline-block;
      }

      .line-circle {
        width: 16px;
        height: 16px;
        border-radius: 8px;
        background-color: app_variables.$archiveVenteNetwork;
        margin-bottom: -3px;

        @media print {
          background-color: unset;
          box-shadow: inset 0 0 0 1000px app_variables.$archiveVenteNetwork !important;
        }
      }
    }
  }
}

esk-ventes-table-negociees-natures {
  table {
    tr.table-total {
      font-weight: bold;

      td {
        border-top: 1px solid variables.$grey;
        border-bottom: 0;
      }
    }
  }
}

.vente-modal-compromis {
  .modal-body {
    .compromis-first {
      .first-dates {
        height: 32px;
        padding: 0 calc((100% - 31px * 2 - 145px * 2) / 2) 60px;
        border-bottom: 1px variables.$gainsboro solid;
        margin-bottom: 30px;

        > * {
          float: left;
        }

        h4 {
          margin-top: 8px;
          margin-right: 10px;
        }
      }

      .first-options {
        margin-bottom: 20px;

        button {
          display: block;
          width: 140px;
          margin: 40px 65px 0;
          font-size: 25px;
        }
      }
    }

    .compromis-second {
      > div.col-xs-12 {
        margin-top: 20px;
      }
    }
  }
}
