@use 'sass:math';
@use "sass:color";
@use "../../../variables" as variables;


// Fonts
$font-family-for-tw: variables.$font-family-base;
$font-size-large: math.floor(variables.$font-size-base * 1.2);
$font-size-mega-large: math.floor(variables.$font-size-base * 1.6);
$font-size-giga-large: math.floor(variables.$font-size-base * 1.9);
$font-size-small: math.floor(variables.$font-size-base * 0.85);
$font-size-extra-small: math.floor(variables.$font-size-base * 0.75);

// Breakpoints
$screen-xs-min: 480px;
$screen-sm-min: 768px;
$screen-md-min: 1010px;
$screen-lg-min: 1200px;
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);

// Borders
$border-separator-large: 4px solid variables.$gainsboro;
$border-separator-dashed: 1px dashed variables.$gainsboro;

// Multiplier
$multiply-2: 2em;
$multiply-4: 4em;

// Container
$container-padding-side: variables.$container-padding-side;
$container-padding-side-sm: calc($container-padding-side / 2);

// Named
$snow: #FAFAFA;

// Noty
$resultGreen: #08BD44;
$labelColored: #858585;

// Thème
$lightPrimary: color.adjust(variables.$primary, $lightness: 10%);
$light15Primary: color.adjust(variables.$primary, $lightness: 15%);
$light20Primary: color.adjust(variables.$primary, $lightness: 20%);
$dark05Primary: color.adjust(variables.$primary, $lightness: -5%);
$darkPrimary: color.adjust(variables.$primary, $lightness: -10%);
$dark20Success: color.adjust(variables.$success, $lightness: -20%);
$info: #46B8DA;
$lightWarning: color.adjust(variables.$warning, $lightness: 10%);
$light15Warning: color.adjust(variables.$warning, $lightness: 15%);
$light20Warning: color.adjust(variables.$warning, $lightness: 20%);
$dark05Warning: color.adjust(variables.$warning, $lightness: -5%);
$dark15Warning: color.adjust(variables.$warning, $lightness: -15%);
$lightDanger: color.adjust(variables.$danger, $lightness: 10%);
$light15Danger: color.adjust(variables.$danger, $lightness: 15%);
$light20Danger: color.adjust(variables.$danger, $lightness: 20%);
$dark05Danger: color.adjust(variables.$danger, $lightness: -5%);
$dark15Danger: color.adjust(variables.$danger, $lightness: -15%);
$red: #C82E29;
$lightRed: color.adjust($red, $lightness: 10%);
$lightOrange: color.adjust(variables.$orange, $lightness: 10%);
$lightGreen: color.adjust(variables.$green, $lightness: 10%);
$light15Green: color.adjust(variables.$green, $lightness: 15%);
$light20Green: color.adjust(variables.$green, $lightness: 20%);
$dark05Green: color.adjust(variables.$green, $lightness: -5%);
$dark15Green: color.adjust(variables.$green, $lightness: -15%);
$darkPink: variables.$pinkDark;
$purple: #804C75;
$light50Purple: color.adjust($purple, $lightness: 50%);
$azure: #0095C8;
$light05Azure: color.adjust($azure, $lightness: 5%);
$lightAzure: color.adjust($azure, $lightness: 10%);
$light15Azure: color.adjust($azure, $lightness: 15%);
$light20Azure: color.adjust($azure, $lightness: 20%);
$dark05Azure: color.adjust($azure, $lightness: -5%);
$dark15Azure: color.adjust($azure, $lightness: -15%);
$light05Grey: color.adjust(variables.$grey, $lightness: 5%);
$light08Grey: color.adjust(variables.$grey, $lightness: 8%);
$lightGrey: variables.$greyLight;
$light20LightGrey: color.adjust($lightGrey, $lightness: 20%);
$light30LightGrey: color.adjust($lightGrey, $lightness: 30%);
$dark05LightGrey: color.adjust($lightGrey, $lightness: -5%);
$light12Grey: color.adjust(variables.$grey, $lightness: 12%);
$dark05Grey: color.adjust(variables.$grey, $lightness: -5%);
$darkGrey: color.adjust(variables.$grey, $lightness: -10%);
$yellow: #FFB848;
$darkYellow: color.adjust($yellow, $lightness: -10%);
$bricky: #894550;
$black: #2C2F3B;

// Layout
$appBackground: #F0F3F4;
$layoutColor: #485058;
$sidebarColor: #788290;
$dark06Sidebar: color.adjust($sidebarColor, $lightness: -6%);

// Texts and links
$textDark: color.adjust(variables.$textColor, $lightness: -20%) !default;
$lightTextColor: variables.$textMutedColor;
$light25TextColor: color.adjust(variables.$textColor, $lightness: 25%);
$eskExtraLightGrey: rgba(192, 197, 202, 0.15);
$eskLightGrey: rgba(34, 36, 38, 0.15);
$eskDarkGrey: variables.$GreyOfDavy;

// Graphe
$archiveVenteSolo: rgb(28, 51, 82);
$archiveVenteSoloPrevious: rgba($archiveVenteSolo, 0.7);
$archiveVenteDuo: rgb(233, 64, 97);
$archiveVenteDuoPrevious: rgba($archiveVenteDuo, 0.7);
$archiveVenteNetwork: variables.$greenNoty;
