@use "../../../../variables" as variables;
@use "../variables" as app_variables;

// Labels and Badges
.badge {
  background-color: variables.$primary;
}

.badge-success {
  background-color: variables.$success;
}

.badge-warning {
  background-color: variables.$warning;
}

.badge-info {
  background-color: app_variables.$info;
}

.badge-danger {
  background-color: variables.$danger;
}

.badge-new {
  background-color: variables.$primary;
  border-radius: 12px 12px 12px 12px !important;
  font-size: variables.$font-size-small !important;
  font-weight: 300;
  height: 18px;
  padding: 3px 6px;
  text-align: center;
  text-shadow: none !important;
  vertical-align: middle;
}

.badge-inverse,
.label-inverse {
  background-color: app_variables.$eskDarkGrey;
}

.label-default {
  background-color: variables.$primary;
}

.label-price,
.label-rent,
.label-valeur {
  background-color: app_variables.$layoutColor;
}

.label-localisation {
  background-color: app_variables.$info;
}

.label-nature {
  background-color: variables.$green;
}

.label-brouillon {
  background-color: app_variables.$darkPink;
}

.label-confrere {
  background-color: app_variables.$light05Azure;
}

.label-results {
  color: white;
  margin: 2px;
}
