@use "../../../../variables" as variables;

.collection-sortable {
  position: relative;

  .collection-sortable-dragger {
    color: variables.$textMutedColor;
    cursor: move;

    > div {
      transform: rotate(90deg);
      font-size: 1.5em;
      font-weight: 900;
      text-align: center;
      letter-spacing: 4px;
      color: variables.$greyLight;

      &:hover {
        color: variables.$textMutedColor;
      }
    }
  }

  .collection-sortable-release {
    display: none;
    position: absolute;
    inset: 0;
    border-radius: 0.25rem;
    background-color: variables.$greyLight;

    > div {
      display: flex;
      align-items: center;
      justify-content: left;
      height: 100%;
      width: 100%;
      margin-left: 1rem;
    }
  }

  &.collection-sortable-dragover {
    *:not(.collection-sortable-release) {
      pointer-events: none;
    }

    .collection-sortable-release {
      display: block;
    }
  }
}

.collection-sortable-table {
  thead tr th:first-child {
    width: 28px;
  }

  .collection-sortable-dragger {
    display: table-cell;
    width: 1rem;

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 2rem;
    }
  }
}

.collection-sortable-ul .collection-sortable {
  display: flex;

  > *:not(.collection-sortable-dragger, .collection-sortable-release, .collection-sortable-dragover) {
    flex: 1 1 0%;
  }

  .collection-sortable-dragger {
    display: flex;
    align-items: center;
  }
}
