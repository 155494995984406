@use "../../../variables" as variables;

.tox-tinymce {
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  .tox-sidebar-wrap {
    padding: 10px 0;
    background-color: variables.$whiteSmoke;

    .tox-edit-area {
      border-top: 0;
      padding-left: calc(-0.5 * 100% + 390px); /* Affichage de la partie gauche de l'éditeur */

      @media (max-width: variables.$screen-md-max) {
        overflow: scroll; /* Possibilité de scroller dans l'éditeur, surtout en gauche droite pour les petits écrans */
      }

      iframe {
        $iframe-html-width: 210mm;
        $scrollbar-width: 15px;
        $iframe-width: $iframe-html-width + $scrollbar-width;

        width: $iframe-width !important;
        margin: 0 calc((100% - #{$iframe-width}) / 2);
      }
    }
  }
}
