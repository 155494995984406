.tw-dropdown-link {
  @apply tw-flex tw-justify-start tw-items-center tw-w-full tw-px-4 tw-py-2 tw-space-x-2 tw-whitespace-nowrap tw-text-sm tw-text-gray-700;
  @apply hover:tw-bg-gray-100 hover:tw-text-gray-900;
  @apply focus:tw-bg-gray-100 focus:tw-text-gray-900 focus:tw-outline-none;

  & > fa-icon,
  & > fa-stack,
  & > .tw-fa-custom-stack > .tw-fa-custom-stack-main {
    @apply tw-w-4 #{!important};
    @apply tw-h-auto tw-text-gray-500;
  }

  & > .tw-fa-custom-stack {
    @apply tw-flex tw-relative;

    .tw-fa-custom-stack-secondary {
      @apply tw-absolute tw-text-gray-500;

      right: -1px;
      bottom: -5px;
    }
  }
}
