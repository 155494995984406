@use "src/app/tw-app";

@tailwind base;
@tailwind components;

.disabled\:tw-disabled[disabled] {
  @apply tw-opacity-60;

  &, * {
    @apply tw-pointer-events-none;
  }
}

.tw-waiting,
.disabled\:tw-waiting[disabled] {
  @apply tw-opacity-80 tw-animate-pulse;

  &, * {
    @apply tw-pointer-events-none;
  }
}

@tailwind utilities;

.tw-text-size-inherit {
  font-size: inherit;
  line-height: inherit;
}

