@use "../../../variables" as variables;

table.table-collection {
  table-layout: fixed;

  > thead > tr > th {
    border: 0;
    color: variables.$textColor;
    text-align: center;
  }

  > tbody > tr > td {
    border: 0;
    position: relative;

    input {
      border: 0;
      border-bottom: variables.$border-separator;
    }

    &.has-error {
      .form-control {
        border-top-width: 0 !important;
        border-right-width: 0 !important;
        border-left-width: 0 !important;
      }

      .error {
        position: absolute;
      }
    }

    .num-icon {
      position: absolute;
      bottom: 16px;
      right: 10px;
      color: variables.$textDark;

      & + input {
        padding-right: 25px;
      }

      @media (max-width: variables.$screen-xs-max) {
        display: none;

        & + input {
          padding-right: 4px;
        }
      }
    }
  }
}
