@import "../../../variables";

.modal-without-overflow {
  max-height: calc(100vh - 2 * 0.25rem);

  @media (min-width: $tw-min-sm) {
    max-height: calc(100vh - 2 * 1rem);
  }
  @media (min-width: $tw-min-lg) {
    max-height: calc(100vh - 2 * 2rem);
  }
}
