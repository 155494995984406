@use "../../../variables" as variables;
@use "_variables" as app_variables;

///////////////
// ui-select //
///////////////
.ui-select-placeholder,
.ui-select-match-text > span {
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
}

.ui-select-bootstrap {
  > .ui-select-choices {
    top: 32px !important;
  }

  > .ui-select-match {
    > .btn {
      overflow: hidden;
    }
  }

  .ui-select-match-text {
    padding-right: 2em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.ui-select-multiple {
  .ui-select-match {
    display: block;
  }

  .ui-select-match:not(:empty) {
    border-bottom: variables.$border-separator;
    padding-bottom: 5px;
  }
}

.ui-select-container:not(.ui-select-multiple) input.ui-select-search {
  width: 100% !important;
}

.ui-select-choices-group-label {
  text-transform: capitalize;
}

.ui-select-match {
  .ui-select-toggle {
    .btn-link {
      color: variables.$primary;
    }
  }

  .ui-select-match-item {
    font-size: variables.$font-size-base;
  }

  &.btn-default-focus {
    outline: none;
  }
}

.ui-select-no-choice {
  .content {
    padding: 10px;
    font-style: italic;
    color: app_variables.$red;
    display: block;
  }

  &.dropdown-menu {
    padding: 0;
  }
}

///////////
// swal2 //
///////////
body {
  padding: 0 !important; // Utile car si on ouvre une modale UIB avec derrière une confirmation SWAL, il y a un padding-right de la largeur de l'ascenseur qui s'ajoute de plus en plus.
}

.swal2-container.swal2-center.swal2-backdrop-show {
  z-index: 99999;

  .swal2-modal {
    font-size: variables.$font-size-extra-large;

    .swal2-checkbox.checkbox {
      margin-top: 20px;

      span {
        cursor: pointer;
        display: inline-block;
        font-size: variables.$font-size-base;
        margin-right: 15px;
        padding-left: 30px;
        position: relative;
        line-height: 23px;
        transition: border 0.2s linear 0s, color 0.2s linear 0s;
        white-space: nowrap;

        &:before {
          background-color: white;
          border: 1px solid variables.$grey;
          content: "";
          display: inline-block;
          height: 20px;
          left: 0;
          margin-right: 10px;
          position: absolute;
          width: 20px;
          border-radius: 0;
          top: 1px;
          transition: border 0.2s linear 0s, color 0.2s linear 0s;
        }

        &:after {
          display: inline-block;
          font-size: variables.$font-size-small;
          height: 19px;
          left: 6px;
          position: absolute;
          top: -1px;
          transition: border 0.2s linear 0s, color 0.2s linear 0s;
          width: 19px;
        }
      }

      input[type="checkbox"] {
        display: none;

        &:checked {
          + span:before {
            border-width: 10px;
          }

          + span:after {
            color: white;
            content: "\2713";
          }
        }
      }

      &.check-success input[type="checkbox"]:checked + span:before {
        border-color: variables.$success;
      }

      &.check-primary input[type="checkbox"]:checked + span:before {
        border-color: variables.$primary;
      }

      &.check-warning input[type="checkbox"]:checked + span:before {
        border-color: variables.$warning;
      }

      &.check-danger input[type="checkbox"]:checked + span:before {
        border-color: variables.$danger;
      }

      &.check-info input[type="checkbox"]:checked + span:before {
        border-color: app_variables.$info;
      }

      &.check-purple input[type="checkbox"]:checked + span:before {
        border-color: app_variables.$purple;
      }

      &.check-green input[type="checkbox"]:checked + span:before {
        border-color: variables.$green;
      }
    }
  }
}
