@use "../../../../variables" as variables;
@use "../variables" as app_variables;

// Panels
.panel {
  background: none;
  box-shadow: none;
  border-radius: 3px;
  color: variables.$textColor;
  border: none;
  position: relative;

  .panel-heading {
    position: relative;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    min-height: 40px;
  }

  .panel-footer {
    position: relative;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: inherit;
    padding: 15px;
    background: none;
  }
}

.panel-white {
  background-color: white;
  position: relative;
  color: app_variables.$textDark;
  border: 1px solid rgba(black, 0.2);
}
