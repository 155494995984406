@use "../../../../variables" as variables;
@use "../variables" as app_variables;

// @todo https://gitlab.soqrate.com/soqrate/noty/noty/-/issues/100 : A supprimer
.messages-list {
  border-right: 1px solid app_variables.$light20LightGrey;
  list-style: none;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
  bottom: 0;

  .messages-item {
    border-bottom: 1px solid variables.$grey;
    padding: 10px 15px 5px 25px;
    position: relative;

    &:hover {
      background-color: app_variables.$light30LightGrey;
      cursor: pointer !important;
    }

    &.active {
      background-color: app_variables.$light30LightGrey;
    }

    &:last-child {
      padding-bottom: 3rem;
    }
  }
}

.no-messages {
  opacity: 0.5;
  text-align: center;
  margin-top: 200px;
}

.suivi-report {
  .report-message {
    .message-actions {
      width: calc(100% - 30px);
      border-bottom: variables.$border-separator;
      margin: 0 15px;

      .actions-list {
        height: 65px;
        padding: 15px 0;
      }
    }

    .message-header {
      border-bottom: 1px solid app_variables.$light20LightGrey;
      padding: 25px 15px 10px;

      .message-from {
        font-weight: bold;
        color: app_variables.$textDark;
      }

      .message-time {
        color: app_variables.$lightTextColor;
        font-size: app_variables.$font-size-small;
      }
    }

    .message-subject {
      padding: 20px 10px 0;
      color: app_variables.$textDark;
    }

    .message-content {
      padding: 20px 10px;
      color: variables.$textColor;
    }
  }
}
