@use "../scss/template/base";
@use "../scss/template/buttons";
@use "../scss/template/form-elements";
@use "../scss/template/labels-and-badges";
@use "../scss/template/layout";
@use "../scss/template/messages";
@use "../scss/template/modals";
@use "../scss/template/pagination";
@use "../scss/template/panels";
@use "../scss/template/tables";
@use "../scss/template/tabs";
@use "../scss/template/utilities";

[ng-click] {
  cursor: pointer;
}

@media print {
  *,
  *:before,
  *:after {
    transition: none !important;
  }

  table thead,
  table tfoot {
    display: table-row-group;
  }

  table {
    page-break-after: auto;
  }

  tr,
  td {
    page-break-inside: avoid;
    page-break-after: auto;
  }

  thead {
    display: table-header-group;
  }

  tfoot {
    display: table-footer-group;
  }

  #app .main-content .container {
    padding: 0;
  }

  .print-search-demandeur {
    width: 50%;
  }
}
