@use "../../../../variables" as variables;
@use "../variables" as app_variables;

.location {
  tabs {
    .tab-container {
      margin-bottom: 10px;

      .tab-content {
        display: none;
      }
    }
  }

  .location-edit {
    fieldset {
      .h5-fieldset {
        color: variables.$primary;
        font-size: app_variables.$font-size-large;
        font-weight: 400;
      }

      .charges-locatives {
        margin-top: 30px;

        .form-group {
          margin-bottom: 5px;

          a {
            padding: 0 5px;
          }

          label {
            margin-top: 5px;
          }
        }
      }
    }
  }
}

.loyer-base {
  a {
    padding: 0 10px;
  }

  .popover {
    width: max-content;
  }
}

.list-locations {
  .select-all {
    .clip-check input[type="checkbox"]:checked + label:before {
      border-color: variables.$primary;
    }
  }

  .bullet-compromis {
    display: none;
  }
}
