@use "../../../../../variables" as variables;
@use "../../variables" as app_variables;

esk-chart-negociation-revenus {
  .negociation-revenus-header {
    margin-left: 60px;

    .header-legend {
      padding-top: 10px;

      .legend-bars {
        display: inline-block;

        > * {
          display: inline-block;
          width: 30px;
        }

        .legend-bar-carre {
          > * {
            height: 15px;
          }

          .bar-solo {
            background-color: app_variables.$archiveVenteSolo;
          }

          .bar-duo {
            background-color: app_variables.$archiveVenteDuo;
          }
        }

        @media print {
          .legend-bar-carre {
            .bar-solo {
              background-color: unset;
              box-shadow: inset 0 0 0 1000px app_variables.$archiveVenteSolo !important;
            }

            .bar-duo {
              background-color: unset;
              box-shadow: inset 0 0 0 1000px app_variables.$archiveVenteDuo !important;
            }
          }
        }
      }

      .btn-null {
        margin-top: -20px;
      }
    }

    .header-values {
      > * {
        height: 20px;
      }

      .values-label,
      .values-value {
        float: left;
      }

      .values-label {
        width: 130px;
        font-weight: 300;
      }

      .values-value {
        width: 90px;
        text-align: right;
        font-weight: 500;
      }
    }

    @media print {
      > * {
        width: 50%;
      }
    }
  }
}

.negociation {
  .negociation-container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

    @media (min-width: app_variables.$screen-md-min) {
      max-width: calc(#{app_variables.$screen-md-min} - 40px);
    }

    .negociation-revenus {
      .negociation-revenus-criteria {
        margin-bottom: 10px;
        padding: 15px 0;
        border-bottom: variables.$border-separator;
      }

      .negociation-revenus-table {
        margin-top: 50px;
      }

      @media print {
        .negociation-revenus-criteria {
          display: none;
        }

        esk-chart-negociation-revenus {
          height: 450px;
          display: block;
          position: relative;

          > * {
            height: 100%;

            > * {
              height: 100%;
            }
          }

          .negociation-revenus-canvas {
            max-width: 100%;
            height: calc(100% - 60px - 20px) !important;
            position: relative;

            canvas {
              position: absolute;
              left: 0;
              top: 0;
              right: 0;
              bottom: 0;
              width: 100% !important;
              height: 100% !important;
            }
          }

          p {
            bottom: 0;
            right: 0;
            position: absolute;
          }
        }

        .negociation-revenus-table {
          margin-top: 20px;
        }
      }
    }
  }
}
