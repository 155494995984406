@use "../../../../../variables" as variables;

// Here take place some flex
.dashboard {
  .display-flex:before,
  .display-flex:after {
    display: inline-block;
  }

  .row {
    &.display-flex {
      display: flex;
      flex-wrap: wrap;

      .ui-sortable .ui-sortable-placeholder {
        margin-bottom: 15px;
      }

      .col-dnd {
        display: flex;
        flex-direction: column;

        .ui-sortable-placeholder {
          display: flex;
          flex-direction: column;
          height: 100%;

          &.placeholder:after {
            color: variables.$textColor;
            content: "Rel\000E2 cher pour placer \000E0  cette position.";
          }
        }
      }
    }

    .panel {
      .panel-heading {
        text-align: left;

        .panel-title {
          display: inline-block;
          margin-left: 5px;
        }

        .panel-tools {
          .btn {
            background-color: transparent;
            color: variables.$primary;
            padding: 6px;
          }
        }
      }

      &.box-news {
        .panel-tools {
          a {
            padding: 15px;
            cursor: default;
            color: variables.$textColor;
          }
        }

        .panel-body {
          text-align: center;
          padding: 0;
        }
      }

      .twitter-box {
        max-height: 450px;
        overflow: auto;

        .twitter-timeline {
          margin-right: auto;
          margin-left: auto;
        }
      }
    }
  }
}
