input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="search"]:focus,
input[type="search"]:hover {
  &::-webkit-search-cancel-button {
    appearance: auto;
    opacity: 1;
    pointer-events: all;
  }
}

// [class*="tw-input-"] pour éviter les champs gérés par Bootstrap
input[class*="tw-input-"] {
  @apply tw-w-full tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm tw-text-gray-600 focus:tw-outline-none focus:tw-shadow-none focus:tw-ring-0 focus:tw-border-blue-500;
  @apply tw-text-size-inherit; // Pour contrer Bootstrap

  &.tw-input-checkbox {
    @apply tw-w-4 tw-border-gray-500 tw-rounded tw-shadow-none tw-text-blue-500;
    @apply disabled:tw-bg-gray-200 disabled:tw-border-gray-300;
    @apply focus:tw-border-gray-500 focus:tw-ring-2 focus:tw-ring-blue-500;

    &[ng-reflect-model="true"],
    &:indeterminate {
      @apply tw-border-transparent focus:tw-border-transparent;
    }
  }

  // Quand les input n'auront plus de style Bootstrap, on pourra  "&[type=text]" comme sélecteur plutôt qu'une class dédiée
  &.tw-input-number {
    @apply tw-w-32;
  }

  // Quand les input n'auront plus de style Bootstrap, on pourra  "&[type=search]" comme sélecteur plutôt qu'une class dédiée
  &.tw-input-search {
    @apply tw-pl-8;
  }
}

.tw-input-currency {
  @apply tw-table sm:tw-text-sm;

  input {
    @apply tw-table-cell tw-rounded-none tw-rounded-l-md;

    & ~ * {
      @apply tw-table-cell tw-py-2 tw-px-3 tw-border tw-border-l-0 tw-border-gray-300 tw-rounded-r-md tw-shadow-sm tw-bg-gray-200 tw-text-gray-600 tw-text-center;
    }
  }
}
