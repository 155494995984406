@use "../../../../variables" as variables;
@use "../variables" as app_variables;

.resultat-green {
  background-color: app_variables.$resultGreen;
}

.resultat-orange {
  background-color: app_variables.$lightOrange;
}

.resultat-blue {
  background-color: variables.$primary;
}

.resultat-red {
  background-color: app_variables.$lightRed;
}

.bullet-green {
  color: app_variables.$resultGreen;
}

.bullet-orange {
  color: app_variables.$lightOrange;
}

.bullet-blue {
  color: variables.$primary;
}

.bullet-red {
  color: app_variables.$lightRed;
}

.bullet-red.compromis {
  margin-left: 2px;
}

.list-demandeurs {
  .select-all {
    .clip-check input[type="checkbox"]:checked + label:before {
      border-color: app_variables.$purple;
    }
  }

  .table-demandeurs {
    thead {
      .select {
        label {
          margin: 0;
        }
      }
    }

    .line {
      .select {
        padding-right: 0;

        label {
          margin: 0;
          padding: 0;
        }
      }

      .col-reference {
        width: 100px;
      }

      .col-initiales {
        width: 100px;
      }

      .col-nom {
        min-width: 150px;

        * {
          cursor: pointer;
        }
      }

      .col-creation-date {
        width: 180px;
      }

      .col-phone {
        width: 150px;
      }

      .col-nature {
        min-width: 80px;
      }

      .col-localisation {
        min-width: 100px;
      }

      .col-results {
        width: 125px;

        div {
          cursor: pointer;
        }
      }

      .angle-right {
        width: 55px;

        a {
          color: inherit;
        }

        div {
          cursor: pointer;
        }
      }

      .ellipsis {
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }

  .collection-demandeurs {
    .content {
      float: left;
      padding-right: 10px;

      .thumbnail {
        height: 230px;
        width: 200px;
        box-shadow: 0 4px 8px 0 rgba(black, 0.2);
        transition: 0.3s;
        padding: 6px;

        .title-dossier {
          height: 50px;
          color: variables.$textDark;

          div {
            cursor: pointer;
          }

          .ellipsis {
            white-space: initial;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }

        .descriptif-dossier {
          height: 140px;

          .transaction {
            height: 20px;
            text-transform: capitalize;
          }

          .localisation {
            height: 56px;
            overflow: hidden;
          }

          .nature {
            height: 64px;
          }
        }

        .footer-dossier {
          position: relative;
          height: 25px;

          .select {
            display: inline-block;

            .checkbox {
              margin: 0;

              label {
                margin: 0;
              }
            }
          }

          .label {
            position: absolute;
            top: 0;

            div {
              cursor: pointer;
            }
          }
        }
      }

      .thumbnail:hover {
        box-shadow: 0 8px 16px 0 rgba(black, 0.2);
      }
    }
  }
}

.list .btn-group .btn-light-azure .caret {
  border-bottom: 4px dashed;
}

.demandeur-archive .modal-body .reference-vente .ventes-archivees {
  display: inline-block;
  margin-top: -3px;

  .checkbox {
    margin: 0 0 0 30px;

    label {
      color: app_variables.$lightTextColor;
    }
  }
}

.demandeurs {
  .results {
    .results-information {
      > * {
        margin-bottom: 15px;
      }

      .ellipsis {
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-bottom: 10px;
      }

      esk-select-sort {
        max-width: 220px;
        display: block;
      }
    }

    .collection-biens .content:first-child {
      margin-left: 0;
    }
  }

  .menu-item {
    position: relative;

    .badge {
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }
}

.criteria {
  .fieldset-sources {
    margin-bottom: 0;
    padding: 25px calc((100% - 310px) / 2);
  }

  .fieldset-transaction {
    height: 113px;
  }

  .fieldset-natures,
  .fieldset-budget,
  .fieldset-epoques {
    min-height: 113px;
    margin-bottom: 0;
  }

  .fieldset-construction {
    .superficie {
      .input-group {
        margin-bottom: 22px;
      }

      @media (max-width: variables.$screen-sm-max) {
        .input-group {
          margin-bottom: 0;
        }
      }
    }
  }

  .fieldset-localisation {
    .tabbable {
      .nav.nav-pills {
        margin: 0;
      }

      &.no-menu {
        .nav.nav-pills {
          display: none;
        }

        .tab-pane {
          padding: 0;
        }
      }

      .tab-pane {
        padding: 15px 0 0 0;

        .selected-localisations {
          position: relative;

          .list-localisations {
            overflow: hidden;
            height: 32px;
            padding-right: 80px;
            margin: 0 0 10px;

            .item-localisation {
              display: inline-block;

              .badge {
                margin: 2px;
                padding: 4px 8px;
                color: app_variables.$purple;
                background-color: app_variables.$light50Purple;
                cursor: default;

                .badge-name.is-clickable {
                  cursor: pointer;
                }

                button {
                  border: 0;
                  padding: 1px;
                  background-color: transparent;
                }
              }
            }
          }

          .list-localisations.all-localisations {
            height: auto;
          }

          .action-localisations {
            position: absolute;
            top: 0;
            right: 0;

            button.badge {
              border: 0;
              margin: 2px 0;
              padding: 5px 10px;
            }

            .more-localisations {
              background-color: app_variables.$purple;
            }
          }
        }

        .carte {
          padding-top: 40px;

          .carte-quartiers {
            height: 400px;
          }
        }
      }
    }
  }
}

.demandeur-edit form .demandeur-envoi-auto-results {
  float: left;
  padding-left: 15px;

  .popover {
    width: 280px;
  }
}
