.tw-form-2col {
  @apply tw-space-y-8;

  &:not(.tw-form-2col-without-pb) {
    @apply last:tw-pb-12;
  }

  .tw-form-2col-part {
    @apply lg:tw-grid lg:tw-gap-6;
    @apply lg:tw-grid-cols-[18rem_1fr] xl:tw-grid-cols-[22rem_1fr] 2xl:tw-grid-cols-[26rem_1fr];

    .tw-form-left-col {
      @apply tw-px-4 md:tw-px-0;

      .tw-form-left-col-title {
        @apply tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900;
      }

      .tw-form-left-col-explain {
        @apply tw-mt-1 tw-text-sm tw-text-gray-600;
      }
    }

    .tw-form-right-col {
      @apply tw-mt-5 md:tw-mt-0 tw-shadow tw-bg-white md:tw-rounded-md;
      @apply tw-px-4 tw-py-5 md:tw-p-6;
    }
  }
}

.tw-grid-list {
  @apply tw-rounded-lg tw-bg-gray-100 tw-overflow-hidden tw-shadow tw-divide-y tw-divide-gray-200 sm:tw-divide-y-0 sm:tw-grid sm:tw-grid-cols-2 sm:tw-gap-px;

  .tw-grid-list-item {
    @apply tw-relative tw-bg-white tw-p-2;
  }
}

table.tw-table-head-separate-body {
  @apply tw-table-fixed tw-w-full tw-mb-8;

  thead > tr {
    @apply tw-text-left tw-text-sm tw-text-gray-700;

    th > div {
      @apply tw-bg-gray-100 tw-ring-1 tw-ring-transparent tw-mb-4 tw-py-3.5 tw-px-3 tw-font-normal;
    }

    th:first-child > div {
      @apply md:tw-rounded-l-lg tw-pl-4 sm:tw-pl-6;
    }

    th:last-child > div {
      @apply md:tw-rounded-r-lg tw-pr-4 sm:tw-pr-6;
    }
  }

  tbody {
    @apply tw-divide-y tw-divide-gray-200 tw-bg-white;
    @apply md:tw-border-2 md:tw-border-transparent md:tw-ring-2 md:tw-ring-gray-200 md:tw-ring-inset md:tw-rounded-lg;

    tr {
      @apply tw-break-inside-avoid;

      td {
        @apply tw-py-4 tw-px-3 tw-text-sm tw-text-gray-500;
        @apply first:tw-pl-4 first:sm:tw-pl-6;
        @apply last:tw-pr-4 last:sm:tw-pr-6;

        // [class*="tw-input-"] pour éviter les champs gérés par Bootstrap
        input[class*="tw-input-"]:not(.tw-input-checkbox) {
          @apply tw-bg-gray-50/25 tw-border-0 tw-border-b tw-rounded-none;
        }

        &.tw-table-head-separate-body-img {
          @apply tw-py-3;
        }

        &.tw-table-head-separate-body-actions {
          @apply tw-w-px tw-py-2 print:tw-hidden;
        }
      }

      &:first-child {
        td {
          @apply first:md:tw-rounded-tl-lg last:md:tw-rounded-tr-lg;
        }
      }

      &:last-child {
        td {
          @apply first:md:tw-rounded-bl-lg last:md:tw-rounded-br-lg;
        }
      }
    }
  }
}

.tw-second-nav {
  @apply tw-h-full tw-mx-[-15px]; // Surcharge "tw-mx-[-15px]" pour la suppression des marges du thème

  .tw-second-nav-menus,
  .tw-second-nav-menus-header {
    @apply tw-flex tw-justify-between tw-items-center tw-gap-2 md:tw-gap-4 xl:tw-gap-8 tw-border-b tw-border-gray-200 tw-p-2;

    h1.tw-second-nav-menus-title {
      @apply tw-text-2xl tw-uppercase tw-font-bold tw-text-gray-700 tw-truncate;
    }

    .tw-second-nav-menus-title {
      @apply tw-flex-1 tw-flex tw-items-center;
    }
  }

  .tw-second-nav-menus {
    @apply tw-h-[50px];

    & + .tw-second-nav-content {
      @apply tw-h-[calc(100%_-_50px)];
    }
  }

  .tw-second-nav-menus-header {
    @apply tw-h-[73px];

    & + .tw-second-nav-content {
      @apply tw-h-[calc(100%_-_73px)];
    }
  }
}
