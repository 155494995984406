@use "../../../../variables" as variables;
@use "../variables" as app_variables;

// Utilities
.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.inline {
  display: inline;
}

.no-radius {
  border-radius: 0 !important;
}

.no-border {
  border: none !important;
}

.border-right {
  border-right-style: solid;
  border-right-width: 1px;
  border-color: rgba(black, 0.2);
}

.border-left {
  border-left-style: solid;
  border-left-width: 1px;
  border-color: rgba(black, 0.2);
}

.border-top {
  border-top-style: solid;
  border-top-width: 1px;
  border-color: rgba(black, 0.2);
}

.border-bottom {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: rgba(black, 0.2);
}

.border-light {
  border-color: rgba(white, 0.2) !important;
}

.panel-white .border-light,
.border-dark {
  border-color: rgba(black, 0.2) !important;
}

.panel-white .border-dark {
  border-color: rgba(black, 0.3) !important;
}

.inline-block {
  display: inline-block !important;
}

.no-display {
  display: none !important;
}

.padding-5 {
  padding: 5px !important;
}

.padding-10 {
  padding: 10px !important;
}

.padding-15 {
  padding: 15px !important;
}

.padding-20 {
  padding: 20px !important;
}

.padding-25 {
  padding: 25px !important;
}

.padding-30 {
  padding: 30px !important;
}

.padding-35 {
  padding: 35px !important;
}

.padding-40 {
  padding: 40px !important;
}

.padding-bottom-0 {
  padding-bottom: 0 !important;
}

.padding-bottom-5 {
  padding-bottom: 5px !important;
}

.padding-bottom-10 {
  padding-bottom: 10px !important;
}

.padding-bottom-15 {
  padding-bottom: 15px !important;
}

.padding-bottom-20 {
  padding-bottom: 20px !important;
}

.padding-bottom-25 {
  padding-bottom: 25px !important;
}

.padding-bottom-30 {
  padding-bottom: 30px !important;
}

.padding-top-0 {
  padding-top: 0 !important;
}

.padding-top-5 {
  padding-top: 5px !important;
}

.padding-top-10 {
  padding-top: 10px !important;
}

.padding-top-15 {
  padding-top: 15px !important;
}

.padding-top-20 {
  padding-top: 20px !important;
}

.padding-top-25 {
  padding-top: 25px !important;
}

.padding-top-30 {
  padding-top: 30px !important;
}

.padding-left-0 {
  padding-left: 0 !important;
}

.padding-left-5 {
  padding-left: 5px !important;
}

.padding-left-10 {
  padding-left: 10px !important;
}

.padding-left-15 {
  padding-left: 15px !important;
}

.padding-left-20 {
  padding-left: 20px !important;
}

.padding-left-25 {
  padding-left: 25px !important;
}

.padding-left-30 {
  padding-left: 30px !important;
}

.padding-right-0 {
  padding-right: 0 !important;
}

.padding-right-5 {
  padding-right: 5px !important;
}

.padding-right-10 {
  padding-right: 10px !important;
}

.padding-right-15 {
  padding-right: 15px !important;
}

.padding-right-20 {
  padding-right: 20px !important;
}

.padding-right-25 {
  padding-right: 25px !important;
}

.padding-right-30 {
  padding-right: 30px !important;
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.margin-bottom-5 {
  margin-bottom: 5px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-bottom-15 {
  margin-bottom: 15px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.margin-bottom-25 {
  margin-bottom: 25px !important;
}

.margin-bottom-30 {
  margin-bottom: 30px !important;
}

.margin-top-0 {
  margin-top: 0 !important;
}

.margin-top-5 {
  margin-top: 5px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-top-15 {
  margin-top: 15px !important;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-top-25 {
  margin-top: 25px !important;
}

.margin-top-30 {
  margin-top: 30px !important;
}

.margin-right-0 {
  margin-right: 0 !important;
}

.margin-right-5 {
  margin-right: 5px !important;
}

.margin-right-10 {
  margin-right: 10px !important;
}

.margin-right-15 {
  margin-right: 15px !important;
}

.margin-right-20 {
  margin-right: 20px !important;
}

.margin-right-25 {
  margin-right: 25px !important;
}

.margin-right-30 {
  margin-right: 30px !important;
}

.margin-left-0 {
  margin-left: 0 !important;
}

.margin-left-5 {
  margin-left: 5px !important;
}

.margin-left-10 {
  margin-left: 10px !important;
}

.margin-left-15 {
  margin-left: 15px !important;
}

.margin-left-20 {
  margin-left: 20px !important;
}

.margin-left-25 {
  margin-left: 25px !important;
}

.margin-left-30 {
  margin-left: 30px !important;
}

.width-200 {
  width: 200px !important;
}

.vertical-align-middle {
  vertical-align: middle !important;
}

.text-muted {
  color: app_variables.$lightTextColor;
}

.text-bold {
  font-weight: bold;
}

.text-extra-small {
  font-size: app_variables.$font-size-extra-small !important;
}

.text-small {
  font-size: app_variables.$font-size-small !important;
}

.text-emphasis {
  font-style: italic;
}

.text-strike {
  text-decoration: line-through;
}

.text-large {
  font-size: app_variables.$font-size-large !important;
}

.text-extra-large {
  font-size: variables.$font-size-extra-large !important;
}

.text-primary {
  color: variables.$primary !important;
}

.text-grey {
  color: variables.$grey !important;
}

.text-azure {
  color: app_variables.$azure !important;
}

.text-orange {
  color: variables.$orange !important;
}

.text-green {
  color: variables.$green !important;
}

.text-pink {
  color: variables.$pink !important;
}

.text-purple {
  color: variables.$pink !important;
}

.text-bricky {
  color: app_variables.$bricky !important;
}

.text-yellow {
  color: app_variables.$yellow !important;
}

.text-red {
  color: app_variables.$red !important;
}

a.text-white {
  color: rgba(white, 0.6) !important;

  &:hover {
    color: white !important;
  }
}

// Background Colors
.no-bg {
  background: none !important;
}

.full-height-screen-bg {
  height: 100vh;
}

.bg-triangle {
  background: linear-gradient(to bottom left, white 0%, white 50%, app_variables.$snow 50%, app_variables.$snow 100%);
}

.center {
  text-align: center;
}

.float-none {
  float: none;
}
