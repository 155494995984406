@use "../../../../variables" as variables;

.estimation {
  tabs {
    .tab-container {
      margin-bottom: 10px;

      .tab-content {
        display: none;
      }
    }
  }

  .estimation-edit {
    .estimation-type {
      width: 450px;
      position: relative;

      a {
        position: absolute;
        right: 70px;
        bottom: 25px;
      }
    }
  }

  .estimation-evaluation {
    .evaluation-help {
      position: relative;

      .help-popover {
        left: 140px;
        position: absolute;
        top: 25px;
      }
    }

    .evaluation-references {
      esk-estimation-references-crud {
        .crud-actions {
          .btn-group.open {
            .btn-primary.dropdown-toggle:focus {
              border-color: variables.$primary;
            }
          }
        }
      }

      .references-calculs {
        margin: 20px -15px 40px;

        .calculs-value {
          margin-top: 15px;
          padding: 0;
          text-align: center;

          > * {
            display: inline;
          }

          a {
            padding-left: 5px;

            span {
              top: -3px;
            }
          }
        }
      }
    }

    .evaluation-precisions {
      .precisions-map {
        .map-upload-infos {
          padding: 0 15px;
        }
      }
    }

    .popover {
      top: 0;
      width: 250px;
      max-width: 250px;
    }
  }
}

.estimations {
  .wallet {
    .select-all {
      .clip-check input[type="checkbox"]:checked + label:before {
        border-color: variables.$primary;
      }
    }
  }

  // @todo Utile pour contrer l'ajout dans biens.scss de "margin-left: -55px;"
  .estimations-references .collection-biens .content:first-child {
    margin-left: 0;
  }
}

esk-estimation-reference-popover {
  .reference-informations {
    font-weight: bold;

    .row {
      > * {
        margin-bottom: 5px;

        span {
          font-weight: normal;
        }
      }
    }
  }
}
