// Tabs
.tabbable {
  background: none;
  padding: 1px;
  margin-bottom: 15px;

  > .nav {
    margin-bottom: 10px;
  }

  .tab-content {
    background-color: white;
    position: relative;

    .tab-pane {
      display: none;
      padding: 15px;
    }

    .active {
      display: block;
    }
  }
}
