@use "../../../../variables" as variables;

// Pagination
.pagination > li > a,
.pagination > li > span {
  margin-left: 5px;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  color: variables.$primary;
}

.pagination > .active > a,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: variables.$primary;
  border-color: variables.$primary;
}
