@use "tailwind";
@use "app/app-tree";

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

ul,
ul[class*="tw-"] {
  padding: 0;
  margin: 0;
  list-style: none;
}

.bg-modules-fond {
  background-image: url("/assets/images/modules/background.svg");
}

textarea {
  resize: none;
}

@media print {
  *,
  *:before,
  *:after {
    color-adjust: exact;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }

  .print\:tw-overflow-initial {
    overflow: initial;
  }
}
