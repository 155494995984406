@use "../../../../variables" as variables;

// Modals
.modal-backdrop {
  background-color: black;
  bottom: 0;
  position: fixed;
  z-index: 9998 !important;

  &.in {
    opacity: 0.4;
  }
}

.modal {
  z-index: 9999 !important;
}

.modal-content {
  box-shadow: none;
}

.modal-footer,
.modal-header {
  border-color: variables.$gainsboro;
}
