@use "../../../../variables" as variables;
@use "../variables" as app_variables;

// Tables
.table {
  border-collapse: separate;
}

.table > thead > tr > th {
  border-top: none;
}

.table-bordered.table > thead > tr > th {
  border-bottom: variables.$border-separator;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  border-bottom: 1px solid variables.$grey;
  border-top: none;
}

.table-hover > tbody > tr:hover > td,
.table-hover > tbody > tr:hover > th {
  background-color: app_variables.$light12Grey;
}

.table > tbody > tr.active > td,
.table > tbody > tr.active > th {
  background-color: app_variables.$light12Grey !important;
  color: app_variables.$textDark !important;
}

.table > tbody > tr.active:hover > td,
.table > tbody > tr.active:hover > th {
  background-color: app_variables.$light08Grey !important;
  color: app_variables.$textDark !important;
}

th.center,
td.center {
  text-align: center;
}

td.center .dropdown-menu {
  text-align: left;
}

td.small-width {
  width: 15%;
}

.table thead tr {
  color: variables.$colorBlack;
  font-weight: normal;
}

.table.table-bordered {
  border-right: none;
  border-bottom: none;
}

.table.table-bordered > thead > tr > th,
.table.table-bordered > tbody > tr > th,
.table.table-bordered > tfoot > tr > th,
.table.table-bordered > thead > tr > td,
.table.table-bordered > tbody > tr > td,
.table.table-bordered > tfoot > tr > td {
  border-top: none;
  border-left: none;
}

.table.table-bordered thead tr th,
.table thead > tr > th,
.table tbody > tr > th,
.table tfoot > tr > th,
.table thead > tr > td,
.table tbody > tr > td,
.table tfoot > tr > td {
  vertical-align: middle;
}
