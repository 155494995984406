@use "../../../../variables" as variables;
@use "../variables" as app_variables;

.contact-initiales {
  background-color: white;
  // utilisation de la couleur tailwind text-zinc-500
  color: #71717A;
}

.bg-grey-1 {
  background-color: variables.$colorGrey1;
  color: variables.$colorBlack;
}

.bg-grey-2 {
  background-color: variables.$colorGrey2;
  color: white;
}

.bg-grey-3 {
  background-color: variables.$colorGrey3;
  color: white;
}

.bg-grey-4 {
  background-color: variables.$colorGrey4;
  color: white;
}

.bg-grey-5 {
  background-color: variables.$colorGrey5;
  color: white;
}

.bg-red-1 {
  background-color: variables.$colorRed1;
  color: white;
}

.bg-red-2 {
  background-color: variables.$colorRed2;
  color: white;
}

.bg-red-3 {
  background-color: variables.$colorRed3;
  color: white;
}

.bg-red-4 {
  background-color: variables.$colorRed4;
  color: white;
}

.bg-red-5 {
  background-color: variables.$colorRed5;
  color: white;
}

.bg-orange-1 {
  background-color: variables.$colorOrange1;
  color: white;
}

.bg-orange-2 {
  background-color: variables.$colorOrange2;
  color: white;
}

.bg-orange-3 {
  background-color: variables.$colorOrange3;
  color: white;
}

.bg-orange-4 {
  background-color: variables.$colorOrange4;
  color: white;
}

.bg-orange-5 {
  background-color: variables.$colorOrange5;
  color: white;
}

.bg-yellow-1 {
  background-color: variables.$colorYellow1;
  color: white;
}

.bg-yellow-2 {
  background-color: variables.$colorYellow2;
  color: white;
}

.bg-yellow-3 {
  background-color: variables.$colorYellow3;
  color: white;
}

.bg-yellow-4 {
  background-color: variables.$colorYellow4;
  color: white;
}

.bg-yellow-5 {
  background-color: variables.$colorYellow5;
  color: white;
}

.bg-green-1 {
  background-color: variables.$colorGreen1;
  color: white;
}

.bg-green-2 {
  background-color: variables.$colorGreen2;
  color: white;
}

.bg-green-3 {
  background-color: variables.$colorGreen3;
  color: white;
}

.bg-green-4 {
  background-color: variables.$colorGreen4;
  color: white;
}

.bg-green-5 {
  background-color: variables.$colorGreen5;
  color: white;
}

.bg-blue-1 {
  background-color: variables.$colorBlue1;
  color: white;
}

.bg-blue-2 {
  background-color: variables.$colorBlue2;
  color: white;
}

.bg-blue-3 {
  background-color: variables.$colorBlue3;
  color: white;
}

.bg-blue-4 {
  background-color: variables.$colorBlue4;
  color: white;
}

.bg-blue-5 {
  background-color: variables.$colorBlue5;
  color: white;
}

.bg-purple-1 {
  background-color: variables.$colorPurple1;
  color: white;
}

.bg-purple-2 {
  background-color: variables.$colorPurple2;
  color: white;
}

.bg-purple-3 {
  background-color: variables.$colorPurple3;
  color: white;
}

.bg-purple-4 {
  background-color: variables.$colorPurple4;
  color: white;
}

.bg-purple-5 {
  background-color: variables.$colorPurple5;
  color: white;
}

.contacts-group {
  .modal-body {
    .list-group-draggable[dnd-list] {
      min-height: 42px;
      padding-left: 0;
      position: relative;

      > li {
        position: relative;
        padding: 0;
        cursor: pointer;

        &:hover,
        &:focus {
          color: #555;
          background-color: #f5f5f5;
        }

        dnd-nodrag {
          display: block;
          padding: 10px 15px;
        }

        [dnd-handle] {
          cursor: move;
          position: absolute;
        }

        .name {
          margin-left: 20px;
        }
      }

      .dndDraggingSource {
        display: none;
      }

      .dndPlaceholder {
        display: block;
        background-color: variables.$gainsboro;
        padding: 10px 15px;
        min-height: 42px;
      }
    }

    .contacts-group-member-title {
      min-height: 25px;
    }
  }

  .name-group.ellipsis {
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 6px;
  }

  .btn-edit-contacts-group {
    white-space: normal;

    @media (max-width: variables.$screen-md-max) {
      margin-bottom: 20px;
    }
  }
}

.media-contact {
  margin: 0;
  height: 80px;

  .media-contact-left {
    float: left;
    padding-right: 10px;

    .unsubscribe-mynoty {
      text-decoration: line-through;
    }
  }

  .media-contact-body {
    .media-contact-heading {
      cursor: pointer;
    }
  }
}

.list-contacts {
  .select-all {
    .clip-check input[type="checkbox"]:checked + label:before {
      border-color: variables.$primary;
    }
  }

  .table-contacts {
    .line {
      td:not(:first-child) {
        padding: 8px 12px;
      }

      .col-select {
        width: 60px;
      }

      .col-initiales {
        width: 60px;
      }

      .col-nom {
        max-width: 350px;

        .ellipsis {
          white-space: initial;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }

      .col-phone {
        min-width: 150px;

        > * {
          display: block;
          padding: 5px;
        }
      }

      .col-email {
        button {
          padding: 0;
          background: 0;
          border: 0;
          color: variables.$primary;
        }
      }

      .col-actions {
        .btn-group {
          .btn {
            background-color: transparent;
            color: variables.$primary;
          }

          .dropdown-menu {
            left: -200px;
            top: 0;
            bottom: auto;
            width: 200px;

            ul {
              margin-bottom: 0;

              li button {
                width: 100%;
                text-align: initial;
              }
            }
          }
        }
      }
    }
  }
}

esk-contacts-search {
  > * {
    float: left;
  }

  .contacts-search-field {
    width: calc(100% - 50px);
  }

  .contacts-search-help {
    width: 50px;
  }
}

esk-contact-edit .help-email {
  position: absolute;
  top: -8px;
  left: 45px;

  .popover {
    width: 200px;
  }
}

esk-contact-initiales {
  display: block;
  position: relative;
  width: 60px;
  height: 60px;

  .contact-invalid-email-address {
    position: relative;

    span {
      position: absolute;
      top: 5px;
      right: 0;
      font-size: 8px;
    }
  }

  .contact-initiales {
    text-align: center;
    padding: 16px 5px;
    font-size: app_variables.$font-size-mega-large;
  }
}
