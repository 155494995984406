@use "../../../../variables" as variables;
@use "../variables" as app_variables;

// Buttons
.btn {
  transition: all 0.3s ease 0s !important;
  background-image: none !important;
  box-shadow: none;
  outline: 0 !important;
  position: relative;

  &.btn-save {
    position: fixed;
    z-index: 30;
    right: 30px;
    bottom: 65px;
    box-shadow: 0 0 20px 5px white;
  }

  & > :not(first-child) {
    margin-left: .25rem;
  }
}

.btn:after {
  content: "";
  position: absolute;
  transition: all 0.3s ease 0s;
  z-index: -1;
}

.btn:hover,
.btn:focus,
.btn:active,
.btn.active,
.btn.disabled,
.btn[disabled] {
  box-shadow: none;
}

.btn-o {
  background: none !important;
}

.btn-null {
  border: none;
  color: variables.$colorBlack;
  font-weight: bold;
  text-align: initial;
}

.btn-default {
  background-color: white;
  border-color: variables.$grey;
  color: variables.$textColor;

  &:hover {
    background-color: white;
    border-color: app_variables.$dark05Grey;
    color: variables.$textColor !important;
  }

  &:active,
  &.active,
  &.active:focus,
  &:active:focus,
  &:active:hover {
    background-color: variables.$whiteSmoke;
    border-color: app_variables.$light05Grey;
    color: app_variables.$textDark !important;
  }

  &:focus {
    background-color: white;
    border-color: app_variables.$dark05Grey;
    color: app_variables.$textDark !important;
  }

  &.disabled:hover,
  &.disabled:focus,
  &.disabled:active,
  &.disabled.active,
  &[disabled],
  &[disabled]:hover,
  &[disabled]:focus,
  &[disabled]:active,
  &[disabled].active,
  fieldset[disabled] &:hover,
  fieldset[disabled] &:focus,
  fieldset[disabled] &:active,
  fieldset[disabled] &.active {
    background-color: white;
    border-color: app_variables.$lightGrey;
    color: app_variables.$light25TextColor;
  }

  .caret {
    border-top-color: variables.$textColor;
  }

  .dropup & {
    .caret {
      border-bottom: 4px solid variables.$textColor;
    }
  }
}

.btn-primary {
  .open & {
    &.dropdown-toggle {
      background-color: app_variables.$light15Primary;
      border-color: app_variables.$light15Primary;
    }
  }

  background-color: variables.$primary;
  border-color: variables.$primary;
  color: white;

  &:hover {
    background-color: app_variables.$lightPrimary !important;
    border-color: app_variables.$lightPrimary;
    color: white;
  }

  &:active,
  &.active,
  &.active:focus,
  &:active:focus,
  &:active:hover,
  &.dropdown-toggle:active:hover {
    background-color: app_variables.$dark05Primary !important;
    border-color: app_variables.$dark05Primary;
  }

  &:focus {
    background-color: app_variables.$light15Primary;
    border-color: app_variables.$light15Primary;
  }

  &.disabled:hover,
  &.disabled:focus,
  &.disabled:active,
  &.disabled.active,
  &[disabled],
  &[disabled]:hover,
  &[disabled]:focus,
  &[disabled]:active,
  &[disabled].active,
  fieldset[disabled] &:hover,
  fieldset[disabled] &:focus,
  fieldset[disabled] &:active,
  fieldset[disabled] &.active {
    background-color: app_variables.$light20Primary;
    border-color: app_variables.$light20Primary;
    color: white;
  }

  &.btn-o {
    border: 1px solid variables.$primary;
    color: variables.$primary;

    &:hover {
      color: app_variables.$lightPrimary;
      border-color: app_variables.$lightPrimary;
      background: none !important;
    }

    &:active,
    &.active,
    &.active:focus,
    &:active:focus,
    &:active:hover {
      background-color: app_variables.$dark05LightGrey !important;
    }

    &:focus {
      background-color: app_variables.$light20Primary;
      border-color: app_variables.$light20Primary;
      color: app_variables.$lightPrimary;
    }

    &.disabled:hover,
    &.disabled:focus,
    &.disabled:active,
    &.disabled.active,
    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus,
    &[disabled]:active,
    &[disabled].active,
    fieldset[disabled] &:hover,
    fieldset[disabled] &:focus,
    fieldset[disabled] &:active,
    fieldset[disabled] &.active {
      border-color: app_variables.$light20Primary;
      color: app_variables.$light20Primary;
    }

    .caret {
      border-top-color: variables.$primary;
    }
  }

  .caret {
    border-top-color: white;
  }

  .dropup & {
    .caret {
      border-bottom: 4px solid white;
    }
  }
}

.btn-warning {
  .open & {
    &.dropdown-toggle {
      background-color: app_variables.$light15Warning;
      border-color: app_variables.$light15Warning;
    }
  }

  background-color: variables.$warning;
  border-color: variables.$warning;
  color: white;

  &:hover {
    background-color: app_variables.$lightWarning !important;
    border-color: app_variables.$lightWarning;
    color: white;
  }

  &:active,
  &.active,
  &.active:focus,
  &:active:focus,
  &:active:hover,
  &.dropdown-toggle:active:hover {
    background-color: app_variables.$dark05Warning !important;
    border-color: app_variables.$dark05Warning;
  }

  &:focus {
    background-color: app_variables.$light15Warning;
    border-color: app_variables.$light15Warning;
  }

  &.disabled:hover,
  &.disabled:focus,
  &.disabled:active,
  &.disabled.active,
  &[disabled],
  &[disabled]:hover,
  &[disabled]:focus,
  &[disabled]:active,
  &[disabled].active,
  fieldset[disabled] &:hover,
  fieldset[disabled] &:focus,
  fieldset[disabled] &:active,
  fieldset[disabled] &.active {
    background-color: app_variables.$light20Warning;
    border-color: app_variables.$light20Warning;
    color: white;
  }

  &.btn-o {
    border: 1px solid variables.$warning;
    color: variables.$warning;

    &:hover {
      color: app_variables.$lightWarning;
      border-color: app_variables.$lightWarning;
      background: none !important;
    }

    &:active,
    &.active,
    &.active:focus,
    &:active:focus,
    &:active:hover {
      background-color: app_variables.$dark05LightGrey !important;
      border-color: app_variables.$dark15Warning !important;
      color: app_variables.$dark15Warning !important;
    }

    &:focus {
      background-color: app_variables.$light20Warning;
      border-color: app_variables.$light20Warning;
      color: app_variables.$lightWarning;
    }

    &.disabled:hover,
    &.disabled:focus,
    &.disabled:active,
    &.disabled.active,
    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus,
    &[disabled]:active,
    &[disabled].active,
    fieldset[disabled] &:hover,
    fieldset[disabled] &:focus,
    fieldset[disabled] &:active,
    fieldset[disabled] &.active {
      border-color: app_variables.$light20Warning;
      color: app_variables.$light20Warning;
    }

    .caret {
      border-top-color: variables.$warning;
    }
  }

  .caret {
    border-top-color: white;
  }

  .dropup & {
    .caret {
      border-bottom: 4px solid white;
    }
  }
}

.btn-danger {
  .open & {
    &.dropdown-toggle {
      background-color: app_variables.$light15Danger;
      border-color: app_variables.$light15Danger;
    }
  }

  background-color: variables.$danger;
  border-color: variables.$danger;
  color: white;

  &:hover {
    background-color: app_variables.$lightDanger !important;
    border-color: app_variables.$lightDanger;
    color: white;
  }

  &:active,
  &.active,
  &.active:focus,
  &:active:focus,
  &:active:hover,
  &.dropdown-toggle:active:hover {
    background-color: app_variables.$dark05Danger !important;
    border-color: app_variables.$dark05Danger;
  }

  &:focus {
    background-color: app_variables.$light15Danger;
    border-color: app_variables.$light15Danger;
  }

  &.disabled:hover,
  &.disabled:focus,
  &.disabled:active,
  &.disabled.active,
  &[disabled],
  &[disabled]:hover,
  &[disabled]:focus,
  &[disabled]:active,
  &[disabled].active,
  fieldset[disabled] &:hover,
  fieldset[disabled] &:focus,
  fieldset[disabled] &:active,
  fieldset[disabled] &.active {
    background-color: app_variables.$light20Danger;
    border-color: app_variables.$light20Danger;
    color: white;
  }

  &.btn-o {
    border: 1px solid variables.$danger;
    color: variables.$danger;

    &:hover {
      color: app_variables.$lightDanger;
      border-color: app_variables.$lightDanger;
      background: none !important;
    }

    &:active,
    &.active,
    &.active:focus,
    &:active:focus,
    &:active:hover {
      background-color: app_variables.$dark05LightGrey !important;
      border-color: app_variables.$dark15Danger !important;
      color: app_variables.$dark15Danger !important;
    }

    &:focus {
      background-color: app_variables.$light20Danger;
      border-color: app_variables.$light20Danger;
      color: app_variables.$lightDanger;
    }

    &.disabled:hover,
    &.disabled:focus,
    &.disabled:active,
    &.disabled.active,
    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus,
    &[disabled]:active,
    &[disabled].active,
    fieldset[disabled] &:hover,
    fieldset[disabled] &:focus,
    fieldset[disabled] &:active,
    fieldset[disabled] &.active {
      border-color: app_variables.$light20Danger;
      color: app_variables.$light20Danger;
    }

    .caret {
      border-top-color: variables.$danger;
    }
  }

  .caret {
    border-top-color: white;
  }

  .dropup & {
    .caret {
      border-bottom: 4px solid white;
    }
  }
}

.btn-azure {
  .open & {
    &.dropdown-toggle {
      background-color: app_variables.$light15Azure;
      border-color: app_variables.$light15Azure;
    }
  }

  background-color: app_variables.$azure;
  border-color: app_variables.$azure;
  color: white;

  &:hover {
    background-color: app_variables.$lightAzure !important;
    border-color: app_variables.$lightAzure;
    color: white;
  }

  &:active,
  &.active,
  &.active:focus,
  &:active:focus,
  &:active:hover,
  &.dropdown-toggle:active:hover {
    background-color: app_variables.$dark05Azure !important;
    border-color: app_variables.$dark05Azure;
  }

  &:focus {
    background-color: app_variables.$light15Azure;
    border-color: app_variables.$light15Azure;
    color: white;
  }

  &.disabled:hover,
  &.disabled:focus,
  &.disabled:active,
  &.disabled.active,
  &[disabled],
  &[disabled]:hover,
  &[disabled]:focus,
  &[disabled]:active,
  &[disabled].active,
  fieldset[disabled] &:hover,
  fieldset[disabled] &:focus,
  fieldset[disabled] &:active,
  fieldset[disabled] &.active {
    background-color: app_variables.$light20Azure;
    border-color: app_variables.$light20Azure;
    color: white;
  }

  &.btn-o {
    border: 1px solid app_variables.$azure;
    color: app_variables.$azure;

    &:hover {
      color: app_variables.$lightAzure;
      border-color: app_variables.$lightAzure;
      background: none !important;
    }

    &:active,
    &.active,
    &.active:focus,
    &:active:focus,
    &:active:hover {
      background-color: app_variables.$dark05LightGrey !important;
      border-color: app_variables.$dark15Azure !important;
      color: app_variables.$dark15Azure !important;
    }

    &:focus {
      background-color: app_variables.$light20Azure;
      border-color: app_variables.$light20Azure;
      color: app_variables.$lightAzure;
    }

    &.disabled:hover,
    &.disabled:focus,
    &.disabled:active,
    &.disabled.active,
    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus,
    &[disabled]:active,
    &[disabled].active,
    fieldset[disabled] &:hover,
    fieldset[disabled] &:focus,
    fieldset[disabled] &:active,
    fieldset[disabled] &.active {
      border-color: app_variables.$light20Azure;
      color: app_variables.$light20Azure;
    }

    .caret {
      border-top-color: app_variables.$azure;
    }
  }

  .caret {
    border-top-color: white;
  }

  .dropup & {
    .caret {
      border-bottom: 4px solid white;
    }
  }
}

.btn-green {
  .open & {
    &.dropdown-toggle {
      background-color: app_variables.$light15Green;
      border-color: app_variables.$light15Green;
    }
  }

  background-color: variables.$green;
  border-color: variables.$green;
  color: white;

  &:hover {
    background-color: app_variables.$lightGreen !important;
    border-color: app_variables.$lightGreen;
    color: white;
  }

  &:active,
  &.active,
  &.active:focus,
  &:active:focus,
  &:active:hover,
  &.dropdown-toggle:active:hover {
    background-color: app_variables.$dark05Green !important;
    border-color: app_variables.$dark05Green;
  }

  &:focus {
    background-color: app_variables.$light15Green;
    border-color: app_variables.$light15Green;
    color: white;
  }

  &.disabled:hover,
  &.disabled:focus,
  &.disabled:active,
  &.disabled.active,
  &[disabled],
  &[disabled]:hover,
  &[disabled]:focus,
  &[disabled]:active,
  &[disabled].active,
  fieldset[disabled] &:hover,
  fieldset[disabled] &:focus,
  fieldset[disabled] &:active,
  fieldset[disabled] &.active {
    background-color: app_variables.$light20Green;
    border-color: app_variables.$light20Green;
    color: white;
  }

  &.btn-o {
    border: 1px solid variables.$green;
    color: variables.$green;

    &:hover {
      color: app_variables.$lightGreen;
      border-color: app_variables.$lightGreen;
      background: none !important;
    }

    &:active,
    &.active,
    &.active:focus,
    &:active:focus,
    &:active:hover {
      background-color: app_variables.$dark05LightGrey !important;
      border-color: app_variables.$dark15Green !important;
      color: app_variables.$dark15Green !important;
    }

    &:focus {
      background-color: app_variables.$light20Green;
      border-color: app_variables.$light20Green;
      color: app_variables.$lightGreen;
    }

    &.disabled:hover,
    &.disabled:focus,
    &.disabled:active,
    &.disabled.active,
    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus,
    &[disabled]:active,
    &[disabled].active,
    fieldset[disabled] &:hover,
    fieldset[disabled] &:focus,
    fieldset[disabled] &:active,
    fieldset[disabled] &.active {
      border-color: app_variables.$light20Green;
      color: app_variables.$light20Green;
    }

    .caret {
      border-top-color: variables.$green;
    }
  }

  .caret {
    border-top-color: white;
  }

  .dropup & {
    .caret {
      border-bottom: 4px solid white;
    }
  }
}

.btn-file {
  position: relative;
  overflow: hidden;

  .svg-inject {
    vertical-align: middle;
  }

  input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    text-align: right;
    opacity: 0;
    outline: 0;
    cursor: inherit;
    display: block;
  }
}

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group {
  margin: 0;
}
