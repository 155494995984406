%tw-btn {
  @apply tw-inline-flex tw-justify-center tw-items-center tw-px-3 tw-py-2 tw-space-x-2 tw-whitespace-nowrap tw-rounded-md;
  @apply tw-text-sm tw-font-semibold tw-transition;
  @apply focus:tw-outline-none focus:tw-ring-2;
  @apply disabled:tw-opacity-75 disabled:tw-text-inherit disabled:tw-cursor-default;
  @apply hover:disabled:tw-text-inherit;

  // Quand on a un bouton avec une icône et que cette icône n'est pas visible, elle est quand même dans le DOM donc tw-space-x-2 est en place.
  // Ce qui suit évite l'exemple du dessus.
  &:has(:first-child.sm\:tw-hidden) {
    @apply sm:tw-space-x-0;
  }
}

%tw-btn-primary {
  @extend %tw-btn;

  @apply tw-shadow-sm tw-ring-1 tw-ring-inset;
}

%tw-btn-secondary {
  @extend %tw-btn;

  @apply tw-shadow-sm tw-ring-1 tw-ring-inset;
}

%tw-btn-tertiary {
  @extend %tw-btn;

  @apply tw-bg-transparent tw-ring-inset;
  @apply hover:tw-ring-2;
  @apply hover:disabled:tw-bg-transparent hover:disabled:tw-ring-0;
}

.tw-btn-primary-default {
  @extend %tw-btn-primary;

  @apply tw-bg-gray-600 tw-text-white;
  @apply hover:tw-bg-gray-500 hover:disabled:tw-bg-gray-600 hover:disabled:tw-ring-gray-400;
  @apply focus:tw-bg-gray-500 focus:tw-ring-gray-300;
  @apply active:tw-bg-gray-50 active:tw-text-gray-800;
}

.tw-btn-secondary-default {
  @extend %tw-btn-secondary;

  @apply tw-bg-white tw-ring-gray-300 tw-text-gray-700;
  @apply hover:tw-bg-gray-50 hover:disabled:tw-bg-white;
  @apply focus:tw-bg-gray-50 focus:tw-ring-gray-400;
  @apply active:tw-bg-gray-400 active:tw-text-gray-900;
}

.tw-btn-tertiary-default {
  @extend %tw-btn-tertiary;

  @apply tw-text-gray-700;
  @apply hover:tw-bg-gray-50 hover:tw-ring-gray-300 hover:tw-text-gray-500;
  @apply focus:tw-ring-gray-400;
  @apply active:tw-text-gray-900;
}

.tw-btn-primary-info {
  @extend %tw-btn-primary;

  @apply tw-bg-blue-500 tw-text-white;
  @apply hover:tw-bg-blue-400 hover:disabled:tw-bg-blue-500 hover:disabled:tw-ring-blue-400;
  @apply focus:tw-ring-blue-300;
  @apply active:tw-bg-blue-600;
}

.tw-btn-secondary-info {
  @extend %tw-btn-secondary;

  @apply tw-bg-white tw-ring-blue-400 tw-text-blue-500;
  @apply hover:tw-bg-blue-50 hover:disabled:tw-bg-white;
  @apply focus:tw-bg-blue-50 focus:tw-ring-blue-500;
  @apply active:tw-bg-blue-300 active:tw-text-blue-700;
}

.tw-btn-tertiary-info {
  @extend %tw-btn-tertiary;

  @apply tw-text-blue-500;
  @apply hover:tw-bg-blue-50 hover:tw-ring-blue-400 hover:tw-text-blue-500;
  @apply focus:tw-bg-blue-50 focus:tw-ring-blue-500;
  @apply active:tw-bg-blue-300 active:tw-text-blue-700;
}

.tw-btn-primary-septeo {
  @extend %tw-btn-primary;

  @apply tw-bg-septeo-blue-800 tw-text-white;
  @apply hover:tw-bg-septeo-blue-700 hover:disabled:tw-bg-septeo-blue-800 hover:disabled:tw-ring-septeo-blue-800;
  @apply focus:tw-ring-septeo-blue-500;
  @apply active:tw-bg-septeo-blue-900;
}

/* @todo tw-btn-secondary-septeo */

/* @todo tw-btn-tertiary-septeo */

.tw-btn-primary-success {
  @extend %tw-btn-primary;

  @apply tw-bg-green-500 tw-text-white;
  @apply hover:tw-bg-green-400 hover:disabled:tw-bg-green-500 hover:disabled:tw-ring-green-500;
  @apply focus:tw-ring-green-200;
  @apply active:tw-bg-green-600;
}

/* @todo tw-btn-secondary-success */

/* @todo tw-btn-tertiary-success */

.tw-btn-primary-warning {
  @extend %tw-btn-primary;

  @apply tw-bg-orange-500 tw-text-white;
  @apply hover:tw-bg-orange-400 hover:disabled:tw-bg-orange-500 hover:disabled:tw-ring-orange-400;
  @apply focus:tw-ring-orange-300;
  @apply active:tw-bg-orange-600;
}

/* @todo tw-btn-secondary-warning */

.tw-btn-tertiary-warning {
  @extend %tw-btn-tertiary;

  @apply tw-text-orange-500;
  @apply hover:tw-bg-orange-50 hover:tw-ring-orange-400 hover:tw-text-orange-500;
  @apply focus:tw-bg-orange-50 focus:tw-ring-orange-500;
  @apply active:tw-bg-orange-300 active:tw-text-orange-700;
}

.tw-btn-primary-danger {
  @extend %tw-btn-primary;

  @apply tw-bg-red-600 tw-text-white;
  @apply hover:tw-bg-red-500 hover:disabled:tw-bg-red-600 hover:disabled:tw-ring-red-600;
  @apply focus:tw-bg-red-500 focus:tw-ring-red-300;
  @apply active:tw-bg-red-50 active:tw-text-red-700;
}

.tw-btn-secondary-danger {
  @extend %tw-btn-secondary;

  @apply tw-bg-white tw-ring-red-400 tw-text-red-500;
  @apply hover:tw-bg-red-50 hover:disabled:tw-bg-white;
  @apply focus:tw-bg-red-50 focus:tw-ring-red-500;
  @apply active:tw-bg-red-300 active:tw-text-red-700;
}

.tw-btn-tertiary-danger {
  @extend %tw-btn-tertiary;

  @apply tw-text-red-500;
  @apply hover:tw-bg-red-50 hover:tw-ring-red-400 hover:tw-text-red-500;
  @apply focus:tw-bg-red-50 focus:tw-ring-red-500;
  @apply active:tw-bg-red-300 active:tw-text-red-700;
}
