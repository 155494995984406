.tw-collection-selection-head,
.tw-collection-selection-row {
  /* Rajouté pour contrer Bootstrap */
  input[type="checkbox"] {
    @apply tw-m-0 tw-rounded-sm;

    &:focus {
      outline: 2px solid transparent;
    }
  }

  @media print {
    @apply tw-hidden;
  }
}

.tw-collection-selection-head {
  @apply tw-w-12;
}
